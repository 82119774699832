import React, {useEffect, useState, useRef} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import SignaturePad from 'signature_pad'

const PodcastConsentForm = () => {
    //do some stuff
    //update props? re-render?
    const [sending, setSending] = useState(false)
    const [selectType, setSelectType] = useState("");
    const [sent, setSent] = useState(false)
    const consentRef = useRef()
    const fullnameRef = useRef()
    const canvasRef = useRef()

    useEffect(()=>{
        if (window.document.documentMode) {
            // Do IE stuff
            window.location.href = "/not-supported"
        }
        let canvas = document.querySelector("canvas");
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        let signaturePad = new SignaturePad(canvas, {
                minDistance: 1
        });
        signaturePad.onBegin = ()=> {
            document.getElementById("signature-pad").style.border ="1px solid #ced4da";
        }
        document.getElementById("clear").addEventListener('click', ()=>{
            console.log('click')
            signaturePad.clear()
        })
        let hasimage = "false";
        
        document.getElementById("submit").addEventListener('click',(e)=>{

            e.preventDefault();
            let formData = new FormData(document.querySelector('form'))
            let signature = signaturePad.toDataURL() 

            let name = formData.get("fullname")
            let witness = formData.get("witness")
            let consent = formData.get("consent")
            let source = formData.get("source")
            let imagedesc = ""
            let imagencoded = ""            

            consentRef.current.classList.remove("is-invalid");
            fullnameRef.current.classList.remove("is-invalid");
            document.getElementById("signature-pad").style.border = "1px solid #ced4da";

            if(!name || !consent || signaturePad.isEmpty()){

                if (!consent){ consentRef.current.classList.add("is-invalid"); }
                if (!name){ fullnameRef.current.classList.add("is-invalid"); }
                if(signaturePad.isEmpty()){ document.getElementById("signature-pad").style.border = "1px solid red"; } 
                return
            }

            setSending(true)

            /*let res = JSON.stringify({
                "name": name,
                "witness": witness,
                "consent": consent,
                "type": "Podcast Consent",
                "signature": signature,
                "image": imagencoded,
                "imagedesc": imagedesc,
                "hasimage": hasimage,
                "source": "consent"
            })*/
            let res  = new FormData();
            res.append('name', name);
            res.append('witness', witness);
            res.append('consent', consent);
            res.append('signature', signature);
            res.append('image', imagencoded);
            res.append('imagedesc', imagedesc);
            res.append('hasimage', hasimage);
            res.append('source', "podcast-consent");
            //console.log(res)
            let url = "https://api.rcg.ninja/consent-form/"
                    //console.log(res)
                    //let url = "https://sqjdop438l.execute-api.us-east-2.amazonaws.com/consent"
                    //let url = "https://70s1asfku2.execute-api.us-east-2.amazonaws.com/default/consentFormTest"
            fetch(url,{
                method: `POST`,
                mode:'no-cors',
                headers: {
                    "Content-Type":'application/json'
                },
                body: res
            }).then(res => res.text).then(res =>{
                console.log(res)
                setSent(true);
                //document.querySelector("#messages").innerText = "Message sent successfully"
                
            }).catch(err => {
                console.log(err)
                //document.querySelector("#messages").innerText = ""
            })
        })

    }, [])


    //send form data to controller, save data and email
    return (
        <>
        <form className={`${!sending ? '':'d-none'}`} action="#" method="POST" onSubmit={()=>false}>
            <Container>
            <Row>
                <Col className="pl-4 pt-4 pb-4">
                    <input name="consent" required ref={consentRef} onChange={() => {consentRef.current.classList.remove("is-invalid")}} className="form-check-input" id="consent" type="checkbox"  />
                    <label className="form-check-label" htmlFor="consent" >I am at least 18 years of age and am competent to contract in my own name. I have read this release before signing below and I fully understand the contents, meaning and impact of this release.</label>
                </Col>
            </Row>
            </Container>
            <Row>
                <Col>
                    <label htmlFor=""></label>
                    <input className="form-control" required type="text" onChange={()=>{fullnameRef.current.classList.remove("is-invalid")}} ref={fullnameRef} name="fullname" id="fullname" placeholder="Your Name *" />
                    <sub>* Denotes Required</sub>
                </Col>
                <Col>
                    <label className=""></label>
                    <input className="form-control" type="text" name="witness" placeholder="Witness Name" />
                </Col>
            </Row>
            <Row>
                <Col>
                <p className="mt4">Your signature *</p>
                <canvas ref={canvasRef}  style={{border:`1px solid #ced4da`, borderRadius:`0.25rem`, height: `200px`, width:`100%`}} id="signature-pad" className="signature-pad"></canvas>

                </Col>
            </Row>
            <Row>
                <Col className="mt-4">
                <input type="hidden" name="source" id="source" value="consent" />
                <button type="submit" id="submit" className="btn btn-dark mr-1">Submit</button>
                <button type="button" className="btn btn-outline-dark" id="clear">Clear</button>
                </Col>
            </Row>
        </form>
        <div className={`${!sending ? 'd-none':''}`}>
            Thanks for submitting!
            <p id="message"></p>
        </div>
        </>
    )
}

export default PodcastConsentForm