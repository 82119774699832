import React from 'react'
import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/footer'
import Layout from '../components/Layout'
import {Container, Row, Col} from "react-bootstrap"
import PodcastConsentForm from '../components/podcastConsentForm'

const ReleaseForm = () => {
    return (
        <Layout>
            <SEO title="RadNet Voluntary Podcast Consent | " />
            <div className="mt4">
                <Header fluid={false} pageName="Podcast Recording, Publication and License Agreement"/>
                <Container>
                    <Row>
                        <Col>
                            <p>This Podcast Recording, Publication and License Agreement (“Agreement”) is by RADNET MANAGEMENT, INC. (“RadNet”) with respect to the following facts:</p>
                            <ol type="A">
                                <li>RadNet records, produces, markets, publishes, and distributes and airs a podcast regarding healthcare, entitled “Your Body” to and on behalf of the general public (the “Podcast”; and.   </li>
                                <li>Subject engages in healthcare and/or wellness business or activities relevant and meaningful to topics addressed on the Podcast; and</li>
                                <li>RadNet desires to interview and engage in a discussion with Subject about such healthcare and/or wellness business or activities on its Podcast; and </li>
                                <li>Subject wishes to promote its healthcare and/or wellness business or activities on the Podcast and otherwise participate in a discussion on the Podcast. </li>
                            </ol>
                            <p>NOW, THEREFORE, for good and valuable consideration the receipt of which is hereby acknowledged, the parties agree as follows:</p>
                            <ol>
                                <li><u>Consent.</u> Subject hereby grants to RadNet, and to RadNet’s affiliates, successors, or other persons acting under RadNet’s permission and authority, the irrevocable, perpetual, unrestricted, royalty free right, license and permission to copyright in RadNet’s own name, and to use, re-use, publish, reproduce and distribute, such audio and/or visual recordings, pictures, composites, or other reproductions thereof, distorted or modified in form or character, without restriction as to changes or alterations, whether in conjunction with Subject's true or fictitious name or in conjunction with other recordings, photographs or printed matter, made through any medium, including website publishing, podcast or other audial medium, or for illustration, education, promotion, art, editorial, advertising, trade, or any purpose whatsoever, in such manner as RadNet deems appropriate for such purposes.  Subject understands that if such audio, picture or image, or recording is published on the web, via podcast or elsewhere, it may be downloaded by any computer user, or any user from anywhere such podcasts or recordings are downloaded.  RadNet agrees not to use the photograph/recording/image in any derogatory manner.  Subject waives the right to inspect or approve the finished product(s) and/or advertising copy or other matter used in connection with the Podcast or otherwise with the final product or the use for which it may be applied.  </li>
                                <li><u>Compensation.</u>  Subject waives any claims to royalties or monetary compensation connected with the Podcast, including any and all recordings, creations or photographs, or the publication or distribution thereof. </li>
                                <li><u>Authority.</u> Subject hereby confirms that it has the legal right to enter into this Agreement, which shall include any audio and/or visual recordings, pictures, composites, or other reproductions thereof, distorted or modified in form or character, whether obtained by RadNet before the date of Subject’s signature or at any time thereafter.  </li>
                                <li><u>Waiver and Release.</u>  Subject hereby releases, discharges and agrees to hold RadNet and/or its affiliates, successors, or those acting under its authority or permission, harmless from any liability whatsoever connected with the photography, recording or creation or the use, re-use or publication of images or recordings, including blurring, distortion, alteration, cropping, or use in composite form, intentional or otherwise, that may occur or be produced in the processing of the Podcast or in association with the Podcast. Subject further agrees to release and discharge RadNet and/or its affiliates, successors, or those acting under its authority or permission, harmless from any and all claims, actions and demands arising out of or in connection with the filming and recording of the Podcast, including, without limitation, any and all claims for invasion of privacy, violations of privacy laws, and damage to person or property as a result of the filming and recording.</li>
                                <li><u>Modifications.</u>  No modifications, amendments or waivers of this Agreement or any of its terms shall be effective against either party unless set forth in writing and signed by both parties.</li>
                                <li><u>Choice of Law; Venue.</u>  The terms and conditions of this Agreement shall be construed in accordance with the laws of the State of California without giving effect to the conflict of law principles thereof.  All disputes related to this Agreement shall be submitted to the exclusive jurisdiction and venue of the state courts within the County of Los Angeles, California.</li>
                                <li><u>Severability.</u>  If one or more of the provisions of this Agreement shall be deemed illegal or unenforceable, it shall not affect the other provisions of this Agreement, which shall remain legal and enforceable.</li>
                                <li><u>Counterparts.</u>  This Agreement may be executed in one or more counterparts, each of which so executed shall be deemed to be an original and such counterparts shall together constitute and be one and the same instrument.</li>
                                <li><u>Entire Agreement.</u> This Agreement sets forth the entire understanding of the parties solely with respect to the subject matter hereof. Any and all previous agreements and understandings between or among the parties regarding the subject matter hereof, whether written or oral, are superseded by this Agreement.</li>
                                <li><u>Headings.</u> All captions contained in this Agreement are for convenience of reference only, do not form a part of this Agreement and shall not affect in any way the meaning or interpretation of this Agreement.</li>
                            </ol>
                        </Col>
                    </Row>
                    <Row className="pb5">
                        <Col>
                            <PodcastConsentForm />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </Layout>
    )
}

export default ReleaseForm
